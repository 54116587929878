.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Contact form */
.contactMeForm {
  width: 100%;
}

.contactMeContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contactMeComponent {
  padding: 1%;
}

.contactMeInputField {
  width: 85%;
  padding: 0.5%;
  font-size: 100%;
  border: 1px solid #ccc;
}

.contactMeComponent textarea {
  width: 85%;
  padding: 1.5%;
  border: 1px solid #ccc;
  height: 10em;
  font-family: inherit;
}

.contactMeInputField {
  width: 85%;
  padding: 1.5%;
}

.contactMeTextField {
  width: 50%;
  padding: 1%;
}

.contactMeContent p {
  font-size: 120%;
}

.writeToIan {
  width: 25%;
  background: var(--chakra-colors-blue-400);
  font-weight: bold;
  color: var(--chakra-colors-gray-800);
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  margin: 10px 5px;
  padding: 1%;
  font-size: 100%;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
}

.writeToIan:hover {
  background: var(--chakra-colors-blue-500)
}